import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tenant, TenantsService } from '../core/tenants';

@Injectable()
export class SetTenantIdHeaderInterceptor implements HttpInterceptor {
  private tenantId: string;
  constructor(private _tenantsService: TenantsService
  ) {
    this.tenantId = environment.tenantId;
    this._tenantsService.tenant$
		.subscribe((tenant: Tenant) => {
      this.tenantId = tenant.id;
		});
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({
      setHeaders: {
        'Xng-Tenant-Id': this.tenantId
      }
    });
    return next.handle(clonedRequest);
  }
}