import { MsalInterceptorConfiguration, MsalGuardConfiguration } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { msalConfig } from "src/app/core/auth/auth.config";
import { environment } from 'src/environments/environment';

export function MSALInstanceFactory(): IPublicClientApplication {
    console.log('msalConfig', msalConfig);
    return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    // todo add more protected resources
    protectedResourceMap.set(
        environment.protectedResources.apiConfig.uri,
        environment.protectedResources.apiConfig.scopes
    );

    if(environment.protectedResources.tenantApiConfig.uri != undefined) {
        protectedResourceMap.set(
            environment.protectedResources.tenantApiConfig.uri,
            environment.protectedResources.tenantApiConfig.scopes
        );
    }

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
    };
}