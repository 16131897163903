<header
    class="flex items-center transition-[height] shrink-0 bg-[#F9FAFB] py-4 lg:py-0 lg:h-[--tw-header-height]"
    id="header">
    <div class="container-fluid flex flex-wrap gap-2 items-center lg:gap-4" id="header_container">
        <div class="flex items-stretch gap-10 grow">
            <div class="flex items-center gap-2.5">
                <button class="lg:hidden btn btn-icon btn-light btn-clear btn-sm"
                    data-drawer-toggle="#mega_menu_wrapper">
                    <i class="ki-filled ki-burger-menu-2"> </i>
                </button>
                <h3 class="text-[#374151] text-lg font-medium hidden md:block">
                    XEngine Portal
                </h3>
            </div>
            <div class="flex items-stretch" id="mega_menu_container">
                <div class="flex items-stretch" data-reparent="true" data-reparent-mode="prepend|lg:prepend"
                    data-reparent-target="body|lg:#mega_menu_container">
                    <div class="hidden lg:flex lg:items-stretch" data-drawer="true"
                        data-drawer-class="drawer drawer-start fixed z-10 top-0 bottom-0 w-full mr-5 max-w-[250px] p-5 lg:p-0 overflow-auto"
                        data-drawer-enable="true|lg:false" id="mega_menu_wrapper">
                        <div class="menu flex-col lg:flex-row gap-5 lg:gap-7.5" data-menu="true" id="mega_menu">
                            <div class="menu-item">
                                <a class="menu-link border-b border-b-transparent menu-item-active:border-b-gray-400 menu-item-here:border-b-gray-400"
                                    [routerLink]="['tools']">
                                    <span
                                        class="menu-title menu-link-hover:text-gray-900 text-2sm text-gray-800 dark:menu-item-here:text-gray-900 dark:menu-item-active:text-gray-900 menu-item-show:text-gray-900 menu-item-here:text-gray-900 menu-item-active:font-medium menu-item-here:font-medium">
                                        Tools
                                    </span>
                                </a>
                            </div>
                            <div class="menu-item">
                                <a class="menu-link border-b border-b-transparent menu-item-active:border-b-gray-400 menu-item-here:border-b-gray-400"
                                    [routerLink]="['clients']">
                                    <span
                                        class="menu-title menu-link-hover:text-gray-900 text-2sm text-gray-800 dark:menu-item-here:text-gray-900 dark:menu-item-active:text-gray-900 menu-item-show:text-gray-900 menu-item-here:text-gray-900 menu-item-active:font-medium menu-item-here:font-medium">
                                        Clients
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-center gap-1.5 lg:gap-3.5">
                <div class="menu menu-default" data-menu="true">
                    <div class="menu-item" data-menu-item-offset="0, 0" data-menu-item-placement="bottom-end"
                        data-menu-item-toggle="dropdown" data-menu-item-trigger="hover">
                        <button class="menu-toggle btn btn-light btn-sm flex-nowrap border border-[#D1D5DB]">
                            <span class="hidden md:inline text-nowrap text-[#374151]" style="width: 280px; text-align: left;">
                                {{ selectedTenant?.name }}
                            </span>
                            <span class="flex items-center lg:ms-4">
                                <i class="ki-filled ki-down !text-xs"> </i>
                            </span>
                        </button>
                        <div class="menu-dropdown py-2 scrollable-y max-h-[250px] border border-[#D1D5DB]">
                            <ng-container *ngFor="let tenant of tenants">
                                <div class="menu-item">
                                    <a class="menu-link text-[#374151]" tabindex="0" (click)="switchTenant(tenant)">
                                        <span class="menu-title">
                                            {{ tenant.name }}
                                        </span>
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row items-end pt-0.5">
                    <span class="inline-flex gap-1 text-gray-800 font-medium text-2xs cursor-pointer hover:text-primary"
                        [cdkCopyToClipboard]="selectedTenant.id">
                        <i class="ki-filled ki-copy"> </i>
                        Tenant ID
                    </span>
                </div>
            </div>
        </div>
        <div class="flex items-center flex-wrap gap-2 lg:gap-3.5">
            <div class="border-e border-gray-200 h-5 mx-1.5 lg:mx-3"></div>
            <div class="menu cursor-pointer" data-menu="true">
                <div class="menu-item" data-menu-item-offset="0, 10px" data-menu-item-placement="bottom-end"
                    data-menu-item-placement-rtl="bottom-start" data-menu-item-toggle="dropdown"
                    data-menu-item-trigger="click|lg:click">
                    <div style="width: 50px; height: 40px;" class="menu-toggle inline-flex items-center justify-center relative text-lg font-medium border border-[#D1D5DB] bg-gray-100 text-gray-800">
                        <i class="ki-filled ki-down !text-xs"> </i>
                    </div>
                    <div class="menu-dropdown menu-default light:border-gray-300 w-screen max-w-[200px]">
                        <div class="menu-separator"></div>
                        <div class="flex flex-col">
                            <div class="menu-item px-4 py-1.5">
                                <button class="btn btn-sm btn-light justify-center" (click)="logout()">
                                    Log out
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<div class="w-full h-[2px] bg-gray-300" style="margin-bottom: 20px;"></div>