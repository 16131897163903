import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { LoadingService } from './loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(private _loadingService: LoadingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this._loadingService.setLoadingStatus(true, request.url);

    return next.handle(request).pipe(
      finalize(() => {
          // Set the status to false if there are any errors or the request is completed
          this._loadingService.setLoadingStatus(false, request.url);
      }));
  }
}
