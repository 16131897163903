import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Tenant, TenantsService } from 'src/app/core/tenants';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MsalService } from '@azure/msal-angular';

@Component({
	selector: 'app-header',
	standalone: true,
	imports: [RouterModule, CommonModule, ClipboardModule],
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
	@HostBinding('attr.data-sticky-class') dataStickyClass = 'transition-[height] fixed z-10 top-0 left-0 right-0 shadow-sm backdrop-blur-md bg-white/70 dark:bg-coal-500/70 dark:border-b dark:border-b-coal-100';
	@HostBinding('attr.data-sticky') dataSticky = 'true';
	@HostBinding('attr.data-sticky-name') dataStickyName = 'header';
	@HostBinding('attr.data-sticky-offset') dataStickyOffset = '200px';
	@HostBinding('id') hostId = 'header';

	tenants: any[] = [];
	selectedTenant: Tenant;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	userName: string | null = null;

	constructor(private _tenantsService: TenantsService,
		private msalService: MsalService,
		private _router: Router,
		private _changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit(): void {
		this._tenantsService.tenants$
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((tenants: any[]) => {
			this.tenants = [...tenants];
			this._changeDetectorRef.markForCheck();
		});

		this._tenantsService.tenant$
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((tenant: Tenant) => {
			this.selectedTenant = tenant;
			this._changeDetectorRef.markForCheck();
		});

		const account = this.msalService?.instance?.getActiveAccount();
        if (account) {
			console.log("account", account);
            this.userName = account.name;
        }
		else {
			console.log("account not set");
		}
	}

	switchTenant(tenant: any): void {
		this._tenantsService.setTenant(tenant);

		this._router.navigate(['/']);
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	logout() {
        this.msalService.logout();
    }
}
