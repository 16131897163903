import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { routes } from './app.routes';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  };

@NgModule({
    imports: [
        RouterModule.forRoot(routes, routerConfig)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}