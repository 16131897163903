import { Injectable, ErrorHandler } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
  constructor(private _router: Router,) {
    super();
  }

  override handleError(error: Error) {
    if (!environment.production) {
      console.error('Unexpected error!', error);
    }

    const extras: NavigationExtras = {
      state: {
        error: error?.message,
        stack: error?.stack
      }
    }

    this._router.navigate(['error/500'], extras);
  }
}
