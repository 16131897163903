import { ErrorHandler, NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { XngModule } from 'src/@xng/xng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingInterceptor } from 'src/@xng/components/xng-loading-bar/loading.interceptor';
import { GlobalErrorHandlerService } from './core/services';
import { RequestsInterceptor } from './core/services/requests.interceptor';
import { AuthModule } from './core/auth';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { XngLoadingBarComponent } from 'src/@xng/components/xng-loading-bar';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    declarations: [AppComponent],
    exports: [],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        XngModule,
        AuthModule,
        HeaderComponent,
        FooterComponent,
        XngLoadingBarComponent,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            countDuplicates: true,
            resetTimeoutOnDuplicate: true,
            progressBar: true,
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestsInterceptor,
            multi: true
        },
        {
            provide : HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi   : true
        },
        { 
            provide: ErrorHandler, 
            useClass: GlobalErrorHandlerService
        },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimationsAsync(),
    ],
})
export class AppModule {}
