import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';


@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    RouterModule,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements AfterViewInit {
  ngAfterViewInit(): void {
  }
}
