import { MsalInterceptorConfiguration, MsalGuardConfiguration } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { msalConfig, protectedResources } from "src/app/core/auth/auth.config";

export function MSALInstanceFactory(): IPublicClientApplication {
    console.log('msalConfig', msalConfig);
    return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    // todo add more protected resources
    protectedResourceMap.set(
        protectedResources.apiConfig.uri,
        protectedResources.apiConfig.scopes
    );

    console.log('protectedResourceMap', protectedResourceMap);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
    };
}