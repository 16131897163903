import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LayoutComponent } from './layouts/layout/layout.component';
import { EmptyLayoutComponent } from './layouts';
import { appResolver } from './app.resolver';

export const routes: Routes = [

  // Redirect empty path to '/tools'
  { path: '', pathMatch: 'full', redirectTo: 'tools' },
  {
    path: '',
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'error',
        children: [
          { path: '404', loadChildren: () => import('./modules/pages/error-404/error-404.module').then((m) => m.Error404Module) },
          { path: '500', loadChildren: () => import('./modules/pages/error-500/error-500.module').then(m => m.Error500Module) }
        ],
      },
    ]
  },
  {
    path: '',
    canActivate: [MsalGuard],
    component: LayoutComponent,
    resolve: {
      appData: appResolver
    },
    children: [
      {path: 'tools', loadChildren: () => import('./modules/tools/tools.module').then(m => m.ToolsModule)},
      {path: 'campaigns', loadChildren: () => import('./modules/campaign/campaign.module').then(m => m.CampaignModule)},
      {path: 'clients', loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule)},
      {path: 'chat', loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule)},
    ]
  },
  
  {path: '**', pathMatch: 'full', redirectTo: 'error/404' },

];