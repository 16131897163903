// import { PrimeNGConfig } from 'primeng/api';
import { AfterViewInit, Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import KTComponents from 'src/metronic/core';
import KTLayout from '../metronic/app/layouts/demo1';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation  : ViewEncapsulation.None,
  })
export class AppComponent implements AfterViewInit, OnInit {
	title = 'XEngine';

  constructor() {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    KTComponents.init();
    KTLayout.init();
  }
}