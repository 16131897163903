import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  private lastToken: string | null = null;

  constructor(private _authService: AuthService) {
    this.lastToken = null;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request has an Authorization header
    const authHeader = req.headers.get('Authorization');

    if (authHeader && authHeader.startsWith('Bearer ')) {
      this.lastToken = authHeader.split(' ')[1]; // Extract the token
    }

    return next.handle(req).pipe(
      tap(() => {
        // Log or store the last token used
        this._authService.setLastToken(this.lastToken);
      })
    );
  }
}