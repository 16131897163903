import { inject } from "@angular/core";
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { forkJoin } from "rxjs";
import { TenantsService } from "./core/tenants/tenants.services";

export const appResolver: ResolveFn<any> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return forkJoin([
        inject(TenantsService).getTenants(),
      ]);
    };