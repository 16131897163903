import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private _progress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private _show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _urlMap: Map<string, boolean> = new Map<string, boolean>();

    get progress$(): Observable<number> {
        return this._progress$.asObservable();
    }

    get show$(): Observable<boolean> {
        return this._show$.asObservable();
    }

    constructor() {}

    setProgress(value: number): void {
        if (value < 0 || value > 100) {
            console.error('Progress value must be between 0 and 100!');
            return;
        }

        this._progress$.next(value);
    }

    setLoadingStatus(status: boolean, url: string): void {
        // Return if the url was not provided
        if (!url) {
            console.error('The request URL must be provided!');
            return;
        }

        if (status === true) {
            this._urlMap.set(url, status);
            this._show$.next(true);
        } else if (status === false && this._urlMap.has(url)) {
            this._urlMap.delete(url);
        }

        // Only set the status to 'false' if all outgoing requests are completed
        if (this._urlMap.size === 0) {
            this._show$.next(false);
        }
    }
}
