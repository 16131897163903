import { Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { LoadingService } from './loading.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'xng-loading-bar',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './xng-loading-bar.component.html',
    styleUrl: './xng-loading-bar.component.scss',
    encapsulation: ViewEncapsulation.None,
})
export class XngLoadingBarComponent implements OnInit, OnDestroy {
    progress = 0;
    show = false;
    private _unsubscribeAll: Subject<unknown> = new Subject<unknown>();

    constructor(private _loadingService: LoadingService,
    ) {}

    ngOnInit(): void {
        this._loadingService.progress$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                this.progress = value;
            });

        this._loadingService.show$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                this.show = value;
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    calculateWidth(value: number) {
      if(value <= 25) return '25%';
      if(value <= 50) return '50%';
      if(value <= 75) return '75%';
      return '100%';
    }
}
