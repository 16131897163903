import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, throwError } from "rxjs";

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {

    constructor(private _router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Response
        return next.handle(req).pipe(
            catchError(error => {
                if (error instanceof ErrorEvent) {
                    console.log(error.message);
                }

                if (error instanceof HttpErrorResponse) {

                    switch(error.status) {
                        case 404:
                            this.handleUnknown(error);
                            break;
                        case 500:
                            this.handleUnknown(error);
                            break;
                        case 502:
                            this.handleBadGateway(error);
                            break;
                        default: break;
                    }
                }

                return throwError(() => error);
            })
        );
    }

    handleUnknown(error: HttpErrorResponse): void {
        console.log('handleUnknown', error);
        this._router.navigate(['/error/500'], { state: { 
            error: error?.message,
            stack: error?.error
            } 
        });
    }
  
    handleBadGateway(error: HttpErrorResponse): void {
        console.log('handleBadGateway', error);
        this._router.navigate(['/error/500'], { state: { 
            error: error?.message,
            stack: error?.error
            } 
        });
    }
    
}