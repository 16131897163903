import {
    LogLevel,
    Configuration,
    BrowserCacheLocation,
} from '@azure/msal-browser';

import { environment } from 'src/environments/environment';

const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.b2c.clientID,
        authority: environment.b2c.authorityUrl + '/' + environment.b2c.b2cPolicy,
        knownAuthorities: [environment.b2c.authorityDomain],
        redirectUri: environment.b2c.redirectUri,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
    },
    system: {
        loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                console.log(message);
            },
            logLevel: LogLevel.Error,
            piiLoggingEnabled: false,
        },
    },
};

export const protectedResources = {
    apiConfig: {
        scopes: ['user.read'],
        uri: 'https://graph.microsoft-ppe.com/v1.0/me',
    },
    tenantApiConfig: {
        scopes: ['user.read'],
        uri: 'https://localhost:4200/*',
    },
};
