<div class="container-fluid">
	<div class="flex flex-col md:flex-row justify-center md:justify-between items-center gap-6 py-5">
		<div class="flex order-2 md:order-1 gap-2 font-normal text-2sm">
			<span class="text-gray-500">
				2024©XEngine Portal
			</span>
		</div>
		<nav class="flex order-1 md:order-2 gap-4 font-normal text-2sm text-gray-600">
			<a class="hover:text-primary" target="_blank" href="https://www.xengines.ai/index.html">
				Docs
			</a>
			<a class="hover:text-primary" target="_blank"
				href="https://www.xengines.ai/index.html">
				FAQ
			</a>
			<a class="hover:text-primary" target="_blank" href="https://www.xengines.ai/contact.html">
				Support
			</a>
		</nav>
	</div>
</div>