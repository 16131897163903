import { NgModule, Optional, SkipSelf } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [
  ]
})
export class XngModule {
    /**
   * Constructor
   */
    constructor(@Optional() @SkipSelf() parentModule?: XngModule) {
      if (parentModule) {
        throw new Error(
          'XngModule has already been loaded. Import this module in the AppModule only!'
        );
      }
    }
}
