import { Component, HostBinding } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'xng-empty-layout',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './empty-layout.component.html',
  styleUrl: './empty-layout.component.scss'
})
export class EmptyLayoutComponent {
  @HostBinding('class') hostClass = 'flex grow';

}
