import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { ChatService } from '../modules/chat/chat.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class EventHubService {
  private _hubConnection: HubConnection | undefined;

  constructor(private _chatService: ChatService,
    private _toastr: ToastrService) {
    let signalRLogging = signalR.LogLevel.Debug;
    const url = environment.api.tenantPortalApiBaseUrl;

    const eventHubUrl = new URL('/eventHub', url).toString();
    console.log(`Connecting to ${eventHubUrl}`);

    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(eventHubUrl, {
        withCredentials: true
      })
      .configureLogging(signalRLogging)
      .withAutomaticReconnect()
      .build();

      this._hubConnection
      .start()
      .then(() => console.log('SignalR connection established'))
      .catch(err => console.error('SignalR connection error:', err));

    this._hubConnection.on('ReceiveMessage', (event: any) => {
      console.log('Received message:', event);

      switch (event.eventType) {
        case 'ChatMessage':
          _chatService.pushNewMessages(event.data).subscribe();
          break;
        case 'Error':
          this._toastr.error(event.data.message, 'Error', {
            timeOut: 5000,
          });
          break
        default:
          console.error('Unknown message type:', event);
      }
    });
  }
}
